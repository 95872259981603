// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-layout {
  height: 100vh;
}

.p-toast .custom-success-toast .p-toast-message-content {
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.p-toast .custom-success-toast .p-toast-message-content .p-toast-message-text .p-toast-detail {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: #047205;
}
.p-toast .custom-success-toast .p-toast-message-content .p-toast-icon-close {
  width: 10px;
}`, "",{"version":3,"sources":["webpack://./src/CommonComponents/layout/Layout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAIQ;EACI,mBAAA;EACA,aAAA;EACA,SAAA;AADZ;AAGgB;EACI,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,cAAA;AADpB;AAKY;EACI,WAAA;AAHhB","sourcesContent":[".store-layout {\n    height: 100vh;\n}\n\n.p-toast {\n    .custom-success-toast {\n        .p-toast-message-content {\n            align-items: center;\n            padding: 1rem;\n            gap: 1rem;\n            .p-toast-message-text {\n                .p-toast-detail {\n                    font-size: 14px;\n                    font-weight: 500;\n                    line-height: 17.07px;\n                    color: #047205;\n                }\n            }\n\n            .p-toast-icon-close {\n                width: 10px;\n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
