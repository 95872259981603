// import React, { createContext, useState, useEffect } from "react";
// import * as getServices from "../services/get";
// import * as postServices from "../services/post";
// import api from "../services/api";
// import {fetchInProgressOrders} from "../services/get";

// export const Context = createContext();

// export const AppContext = ({ children }) => {
//   const [items, setItems] = useState(
//     JSON.parse(localStorage.getItem("items")) || []
//   );

//   const [cartItems, setCartItems] = useState(
//     JSON.parse(localStorage.getItem("cartItems")) || []
//   );

//   const [totalPrice, setTotalPrice] = useState(
//     parseFloat(localStorage.getItem("totalPrice")) || 0
//   );

//   const [inProgressOrdersCount, setInProgressOrdersCount] = useState(0);

//   const [selectedQuantities, setSelectedQuantities] = useState(() => {
//     const storedQuantities = localStorage.getItem("selectedQuantities");
//     return storedQuantities ? JSON.parse(storedQuantities) : {};
//   });

//   const [authToken, setAuthToken] = useState(
//     localStorage.getItem("authToken") || null
//   );

//   const [refreshToken, setRefreshToken] = useState(
//     localStorage.getItem("refreshToken") || null
//   );

//   const [orders, setOrders] = useState([]);
//   const [walletBalance, setWalletBalance] = useState(0);
//   const [keywordList, setKeywordList] = useState([]);

//   useEffect(() => {
//     if (authToken) {
//       handleFetchAllItems();
//       fetchInProgressOrdersCount();
//       handleFetchAllKeyword();
//       localStorage.setItem("authToken", authToken);
//       api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
//     } else {
//       localStorage.removeItem("authToken");
//       delete api.defaults.headers.common["Authorization"];
//     }
//   }, [authToken]);

//   const handleLogin = async (email, password) => {
//     console.log(email, password);
//     try {
//       const response = await postServices.login(email, password);
//       setAuthToken(response.access_token);
//       setRefreshToken(response.refresh_token);
//       localStorage.setItem("refreshToken", response.refresh_token);
//       return response;
//     } catch (error) {
//       console.error("Failed to login:", error);
//       throw error;
//     }
//   };

//   const fetchInProgressOrdersCount = async ()=>{
//     const response = await fetchInProgressOrders();
//     console.log("orders count", response);
//     setInProgressOrdersCount(response);
//   }

//   const handleFetchAllItems = async () => {
//     try {
      
//       const fetchedItems = await getServices.fetchAllItems();
//       // const updatedItems = fetchedItems.map((item) => {
//       //   const storedItem = items.find((i) => i.id === item.id);
//       //   return storedItem ? { ...item, quantity: storedItem.quantity } : item;
//       // });
//       setItems(fetchedItems);
//     } catch (error) {
//       console.error("Failed to fetch all items:", error);
//     }
//   };

//   // useEffect(() => {
//   //   if (items.length === 0 && authToken) {
//   //     handleFetchAllItems();
//   //   }
//   // }, [authToken]);



//   // useEffect(() => {
//   //   localStorage.setItem(
//   //     "selectedQuantities",
//   //     JSON.stringify(selectedQuantities)
//   //   );
//   // }, [selectedQuantities]);



//   const updateCartItems = (itemId, quantity) => {
//     const updatedItems = items.map((item) =>
//       item.id === itemId ? { ...item, quantity } : item
//     );
//     setItems(updatedItems);
//     const newCartItems = updatedItems.filter((item) => item.quantity > 0);
//     setCartItems(newCartItems);
//     const newTotalPrice = updatedItems.reduce(
//       (sum, item) => sum + item.price * (item.quantity || 0),
//       0
//     );
//     setTotalPrice(newTotalPrice);
//     setSelectedQuantities((prev) => ({ ...prev, [itemId]: quantity }));
//   };

//   const clearCart = async () => {
//     setSelectedQuantities({});
//     setTotalPrice(0);
//     setCartItems([]);
//     localStorage.removeItem("selectedQuantities");
//     localStorage.removeItem("totalPrice");
//     localStorage.removeItem("cartItems");
//     setItems([]); 
//     localStorage.removeItem("items");
//     await handleFetchAllItems();
//   };

//   const handlePlaceOrder = async (orderDetails) => {
//     try {
//       const response = await postServices.placeOrder(orderDetails);
//       clearCart();
//       return response;
//     } catch (error) {
//       console.error("Failed to place order:", error);
//       throw error;
//     }
//   };
  

//   const handleFetchPartnerOrdersByStatus = async (
//     status,
//     page = 1,
//     limit = 100
//   ) => {
//     try {
//       const response = await getServices.fetchPartnerOrdersByStatus(
//         status,
//         page,
//         limit
//       );
//       setOrders(response);
//       return response;
//     } catch (error) {
//       console.error("Failed to fetch partner orders by status:", error);
//       throw error;
//     }
//   };

//   const handleFetchAllKeyword = async () => {
//     try {
//       const response =  await getServices.fetchAllKeyword();
//       setKeywordList(response);
//       return response;
//     } catch (error) {
//       console.error("Failed to fetch all Keyword:", error);
//       throw error;
//     }
//   };

//   // const keywords = new Set();
//   // items.forEach((item) => {
//   //   item.keyword_list.split(",").forEach((keyword) => {
//   //     keywords.add(keyword.trim());
//   //   });
//   // });

//   // const keywordItemsData = [...keywords];


//   useEffect(() => {
//     localStorage.setItem("items", JSON.stringify(items));
//   }, [items]);

//   useEffect(() => {
//     localStorage.setItem("totalPrice", totalPrice.toString());
//   }, [totalPrice]);

//   useEffect(() => {
//     localStorage.setItem("cartItems", JSON.stringify(cartItems));
//   }, [cartItems]);

//   useEffect(() => {
//     const validQuantities = Object.fromEntries(
//       Object.entries(selectedQuantities).filter(([_, value]) => value !== null)
//     );
//     localStorage.setItem("selectedQuantities", JSON.stringify(validQuantities));
//   }, [selectedQuantities]);
 

//   return (
//     <Context.Provider
//       value={{
//         // keywordItemsData,
//         setAuthToken,
//         setRefreshToken,
//         keywordList,
//         items,
//         cartItems,
//         setCartItems,
//         totalPrice,
//         setTotalPrice,
//         selectedQuantities,
//         setSelectedQuantities,
//         updateCartItems,
//         handleLogin,
//         authToken,
//         clearCart,
//         handlePlaceOrder,
//         handleFetchPartnerOrdersByStatus,
//         orders,
//         walletBalance,
//         inProgressOrdersCount
//       }}
//     >
//       {children}
//     </Context.Provider>
//   );
// };



import React, { createContext, useState, useEffect, useContext } from "react";
import * as getServices from "../services/get";
import * as postServices from "../services/post";
import { AuthContext } from "./AuthContext";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { authToken } = useContext(AuthContext);

  const [items, setItems] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const [totalPrice, setTotalPrice] = useState(
    parseFloat(localStorage.getItem("totalPrice")) || 0
  );
  const [inProgressOrdersCount, setInProgressOrdersCount] = useState(0);
  const [selectedQuantities, setSelectedQuantities] = useState(() => {
    const storedQuantities = localStorage.getItem("selectedQuantities");
    return storedQuantities ? JSON.parse(storedQuantities) : {};
  });
  const [orders, setOrders] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [keywordList, setKeywordList] = useState([]);

  useEffect(() => {
    if (authToken) {
      fetchInProgressOrdersCount();
      handleFetchAllKeyword();
    }
  }, [authToken]);

  const fetchInProgressOrdersCount = async () => {
    const response = await getServices.fetchInProgressOrders();
    setInProgressOrdersCount(response);
  };

  const handleFetchAllItems = async () => {
    try {
      const fetchedItems = await getServices.fetchAllItems();
      setItems(fetchedItems);
    } catch (error) {
      console.error("Failed to fetch all items:", error);
    }
  };

  const updateCartItems = (itemId, quantity) => {
    const updatedItem = items.find((item) => item.id === itemId);
    //setItems(updatedItems);
    updatedItem.quantity = quantity;
    const existingItem = cartItems.find((item) => item.id === itemId);
    let newCartItems;
    if (!existingItem) {
      if (quantity === 0) {
        return; 
      }
       newCartItems = [...cartItems, updatedItem];
    }
    else{
      if (!quantity || quantity === 0) {
        newCartItems = cartItems.filter((item) => item.id !== itemId);
      }
      else{
      newCartItems = cartItems.map((item) =>
        item.id === itemId ? updatedItem : item
      );
    }
    }
    // const newCartItems = updatedItems.filter((item) => item.quantity > 0);
    setCartItems(newCartItems);
    const newTotalPrice = newCartItems.reduce(
      (sum, item) => sum + item.price * (item.quantity || 0),
      0
    );
    setTotalPrice(newTotalPrice);
    setSelectedQuantities((prev) => ({ ...prev, [itemId]: quantity }));
  };

  const clearCart = async () => {
    localStorage.removeItem("selectedQuantities");
    localStorage.removeItem("totalPrice");
    localStorage.removeItem("cartItems");
    setItems([]);
    localStorage.removeItem("items");
    setSelectedQuantities({});
    setTotalPrice(0);
    setCartItems([]);
    setItems([]); 
    console.log(cartItems);
    await handleFetchAllItems();
  };

  const handlePlaceOrder = async (orderDetails) => {
    try {
      const response = await postServices.placeOrder(orderDetails);
      clearCart();
      return response;
    } catch (error) {
      console.error("Failed to place order:", error);
      throw error;
    }
  };

  const handleFetchPartnerOrdersByStatus = async (
    status,
    page = 1,
    limit = 100
  ) => {
    try {
      const response = await getServices.fetchPartnerOrdersByStatus(
        status,
        page,
        limit
      );
      setOrders(response);
      return response;
    } catch (error) {
      console.error("Failed to fetch partner orders by status:", error);
      throw error;
    }
  };

  const handleFetchAllKeyword = async () => {
    try {
      const response = await getServices.fetchAllKeyword();
      setKeywordList(response);
      return response;
    } catch (error) {
      console.error("Failed to fetch all Keyword:", error);
      throw error;
    }
  };

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  useEffect(() => {
    localStorage.setItem("totalPrice", totalPrice.toString());
  }, [totalPrice]);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const validQuantities = Object.fromEntries(
      Object.entries(selectedQuantities).filter(([_, value]) => value !== null)
    );
    localStorage.setItem("selectedQuantities", JSON.stringify(validQuantities));
  }, [selectedQuantities]);

  return (
    <AppContext.Provider
      value={{
        items,
        cartItems,
        setCartItems,
        totalPrice,
        setTotalPrice,
        selectedQuantities,
        setSelectedQuantities,
        updateCartItems,
        clearCart,
        handlePlaceOrder,
        handleFetchPartnerOrdersByStatus,
        orders,
        walletBalance,
        inProgressOrdersCount,
        keywordList,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
