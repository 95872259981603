import React, {useContext} from 'react';
import "./DispatchOrder.scss";
import { Card, Space, Form, Input } from 'antd';
import arrow from "../../assets/images/keyboard_arrow.svg";
import arrow1 from "../../assets/images/links_arrow_icon.svg"
import { NavLink } from 'react-router-dom';
import { OrderContext } from '../../Context/OrderContext';


const DispatchOrder = ({item, form, onSaveInvoiceDetail}) => {

  const {openSelectAddressModal} = useContext(OrderContext);

  const handleArrowClick = () => {
    openSelectAddressModal(item);
  }

  const onFinish = (values) => {
    onSaveInvoiceDetail(values);
  };

  return (
    <div className='dispatch-order-section flex flex-column gap-4'>
      
      <div className="addressCard_section flex flex-column gap-2">
      <div className="title">Delivery Details</div>
        {item.addressData.map((address, index) => (
              <>
             {address.selectedAddress && <Card key={index} className="card">
              <Space direction="vertical" style={{ width: "100%" }}>
                <div className="flex align-items-center justify-content-between">
                  <Space>
                    <div className="save_as">{address.save_as}</div>
                  </Space>
                    <div className="arrow_icon" onClick={handleArrowClick}>
                      <img src={arrow} alt='arrow' />
                    </div>
                </div>
                <div>
                  <div className="full_name">{address.full_name}</div>
                  <div className="address">{`${address.address_line1}, ${address.address_line2}, ${address.town_city}, ${address.pincode}`}</div>
                </div>
              </Space>
            </Card>
            }
              </>
             
          ))}
      </div>
      
      <div className="other_details_section flex flex-column gap-2">
      <div className="title">Other Delivery Details</div>
      <Form
        form={form}
        name="invoice_form"
        layout="vertical"
        onFinish={onFinish}
        className='custom-form card flex align-items-center gap-4'
      >
         <Form.Item
              name="invoice"
              label="Invoice No."
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="Transport"
              label="Transport Number"
            >
              <Input />
            </Form.Item>
      </Form>
      </div>

      <div className="generate_delivery_challen_section flex flex-column gap-1">
        <NavLink to={""} className="link flex align-items-center gap-3" >Generate Delivery Chalan <img src={arrow1} alt='arrow' /></NavLink>
          <div className='text'>Invoice details are included in this delivery chalan </div>
      </div>
    </div>
  )
}

export default DispatchOrder;
