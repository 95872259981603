// import axios from 'axios';
// import { handleLogout } from './auth'; // Import the logout function

// const api = axios.create({
//   // baseURL: 'http://localhost:3000', // Adjust according to your NestJS server URL
//   baseURL: process.env.REACT_APP_API_URL,
// });



// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('authToken');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       handleLogout(); // Call the logout function on 401 error
//     }
//     return Promise.reject(error);
//   }
// );

// export default api;




import axios from 'axios';
import { handleLogout } from './auth'; // Import the logout function

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refreshToken');
      console.log(refreshToken);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, { refreshToken });

        const { access_token } = response.data;
        localStorage.setItem('authToken', access_token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;

        processQueue(null, access_token);

        return api(originalRequest);
      } catch (err) {
        processQueue(err, null);
        handleLogout(); // Call the logout function on refresh failure
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
