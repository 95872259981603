import React, { useEffect } from 'react';
import "./AddAddress.scss";
import { Form, Input, Checkbox } from 'antd';

const AddAddress = ({ form, onSaveAddress, item }) => {

  useEffect(() => {
    if (item.isEditing && item.addressToEdit) {
      form.setFieldsValue(item.addressToEdit);
    } else {
      form.resetFields();
    }
  }, [item, form]);

  const onFinish = (values) => { 
    if (item.isEditing && item.editIndex !== undefined) {
      onSaveAddress(values, item.editIndex);
    } else {
      onSaveAddress(values);
    }
  };

  return (
    <div className='add-address-section'>
      <div className="title">{item.isEditing ? "Edit Address" : "Add Address"}</div>
      <Form
        form={form}
        name="address_form"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className='form-container'>
          <Form.Item
            name="save_as"
            label="Save address as"
            rules={[{ required: true, message: 'Please input a name for this address!' }]}
          >
            <Input placeholder="e.g., Cart 1" />
          </Form.Item>

          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[{ required: true, message: 'Please input your full name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone_no"
            label="Phone No"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address_line1"
            label="Flat, House no., Building, Company"
            rules={[{ required: true, message: 'Please input your address!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address_line2"
            label="Area, street, Sector, Village"
            rules={[{ required: true, message: 'Please input your area details!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[{ required: true, message: 'Please input your landmark!' }]}
          >
            <Input placeholder="e.g., near Park Hospital" />
          </Form.Item>

          <div className='flex align-items-center gap-4'>
            <Form.Item
              name="pincode"
              label="Pincode"
              rules={[{ required: true, message: 'Please input your pincode!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="town_city"
              label="Town/City"
              rules={[{ required: true, message: 'Please input your town or city!' }]}
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="default_address" valuePropName="checked">
            <Checkbox>Save this address as default address</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default AddAddress;
