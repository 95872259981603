// import React, { useContext } from 'react';
// import "./ViewDispatch.scss";
// import { Avatar, Table } from 'antd';
// import { Context } from '../../Context/AppContext';

// const columns = [
//   {
//     title: <span className="table-heading">Items Name</span>,
//     dataIndex: ['item', 'name'],
//     key: 'name',
//   },
//   {
//     title: <span className="table-heading">Order Fulfilled</span>,
//     children: [
//       {
//         title: <span className="table-sub-heading">Quantity (pcs)</span>,
//         dataIndex: 'quantity_dispatch',
//         key: 'quantity_dispatch',
//       },
//       {
//         title: <span className="table-sub-heading">Price</span>,
//         key: 'price',
//         render: (text, record) => {
//           const totalPrice = record.quantity_dispatch * parseFloat(record.price_per_item);
//           return `Rs. ${totalPrice}`;
//         },
//       },
//     ],
//   },
// ];

// const Summary = ({ data }) => {
//   const totalQuantity = data.reduce((acc, item) => acc + item.quantity_dispatch, 0);
//   const totalPrice = data.reduce((acc, item) => {
//     const price = parseFloat(item.price_per_item);
//     return acc + price * item.quantity_dispatch;
//   }, 0);

//   return (
//     <Table.Summary.Row>
//       <Table.Summary.Cell>Total</Table.Summary.Cell>
//       <Table.Summary.Cell className="total_quantity">{`pcs ${totalQuantity}`}</Table.Summary.Cell>
//       <Table.Summary.Cell className="total_price">{`Rs. ${totalPrice}`}</Table.Summary.Cell>
//     </Table.Summary.Row>
//   );
// };
// const ViewDispatch = ({item}) => {
//   console.log(item);
//   const { generateLabel } = useContext(Context);

//   return (
//     <div className='view-dispatch-section h-full flex flex-column gap-2'>
//       <div className="order_id">Order ID: <span>{item.orderId}</span></div>
//       <div className="table_section flex-1">
//         <Table
//           dataSource={item.orderItems}
//           columns={columns}
//           pagination={false}
//           summary={() => <Summary data={item.orderItems} />}
//         />
//       </div>
//       <div className="comment_section">
//         <div className="comment-heading">Comment</div>
//         <div className="comment_container flex gap-2 py-2">
//           <Avatar>{generateLabel(item.client_name)}</Avatar>
//           <div className="comment_content">
//             <div className="client_name">{item.client_name}</div>
//             <div className="comment">{item.comment}</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ViewDispatch




import React, { useContext } from 'react';
import "./ViewDispatch.scss";
import { Avatar, Table } from 'antd';
import { Context } from '../../Context/AppContext';
import { OrderContext } from '../../Context/OrderContext';

const columns = [
  {
    title: <span className="table-heading">Items Name</span>,
    dataIndex: ['item', 'name'],
    key: 'name',
  },
  {
    title: <span className="table-heading">Order Fulfilled</span>,
    children: [
      {
        title: <span className="table-sub-heading">Quantity (pcs)</span>,
        dataIndex: 'quantity_dispatch',
        key: 'quantity_dispatch',
      },
      {
        title: <span className="table-sub-heading">Price</span>,
        key: 'price',
        render: (text, record) => {
          const totalPrice = record.quantity_dispatch * parseFloat(record.price_per_item);
          return `Rs. ${totalPrice}`;
        },
      },
    ],
  },
];

const Summary = ({ data }) => {
  const totalQuantity = data.reduce((acc, item) => acc + item.quantity_dispatch, 0);
  const totalPrice = data.reduce((acc, item) => {
    const price = parseFloat(item.price_per_item);
    return acc + price * item.quantity_dispatch;
  }, 0);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell>Total</Table.Summary.Cell>
      <Table.Summary.Cell className="total_quantity">{`pcs ${totalQuantity}`}</Table.Summary.Cell>
      <Table.Summary.Cell className="total_price">{`Rs. ${totalPrice}`}</Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

const ViewDispatch = ({ item }) => {
  const { generateLabel } = useContext(OrderContext);

  // Filter out only the confirmed items
  const confirmedItems = item.orderItems.filter(orderItem => orderItem.confirmed === true);

  return (
    <div className='view-dispatch-section h-full flex flex-column gap-2'>
      <div className="order_id">Order ID: <span>{item.orderId}</span></div>
      <div className="table_section flex-1">
        <Table
          dataSource={confirmedItems}  // Use confirmed items for the table data
          columns={columns}
          pagination={false}
          summary={() => <Summary data={confirmedItems} />}
        />
      </div>
      <div className="comment_section">
        <div className="comment-heading">Comment</div>
        <div className="comment_container flex gap-2 py-2">
          <Avatar>{generateLabel(item?.partner?.name)}</Avatar>
          <div className="comment_content">
            <div className="client_name">{item.client_name}</div>
            <div className="comment">{item.comment}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDispatch;
