

import React from 'react';
import "./ViewTimeLine.scss";
import checkCircle from "../../assets/images/CheckCircle.svg";

const ViewTimeLine = ({ item }) => {
  const { createdAt, packedAt, dispatchedAt, deliveredAt } = item;

  const extractDate = (receivedDate) => {
    const date = new Date(receivedDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const extractTime = (receivedDate) => {
    const date = new Date(receivedDate);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    return `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  };

  const events = [
    { status: 'Order Received', date: createdAt },
    { status: 'Order Packed', date: packedAt },
    { status: 'Order Dispatched', date: dispatchedAt },
    { status: 'Order Out for Delivery', date: deliveredAt },
  ];

  return (
    <div className="timeline-container">
      <div className="timeline-steps">
        {events.map((event, index) => (
          <div className="timeline-step" key={index}>
            <div className="timeline-content">
                <img src={checkCircle} alt="check circle" className="inner-circle" />
                <div className="mt-1 mb-1">{event.status}</div>
              <div>{extractDate(event.date)}</div>
              
              <div>{extractTime(event.date)}</div>
            </div>
            {index < events.length - 1 && (
              <div className="timeline-connector"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewTimeLine;
