// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  flex-direction: column;
}
.content .top-section .left-side .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #000000;
}
.content .top-section .right-side .search-container .ant-input-wrapper {
  width: 276px;
}
.content .top-section .right-side .search-container .ant-input-wrapper .ant-input-group-addon {
  display: none;
}
.content .top-section .right-side .search-container .ant-input-wrapper .ant-input-affix-wrapper {
  border-radius: none !important;
  border-radius: 6px;
  border: 1px solid #DBDBDB;
  background-color: #FFFFFF;
}
.content .top-section .right-side .search-container .ant-input-wrapper .ant-input-affix-wrapper img {
  width: 20px;
  margin-right: 0.5rem;
}
.content .top-section .right-side .search-container .ant-input-wrapper .ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #787486;
}
.content .bottom-section {
  flex: 1 1;
  overflow: auto;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/CommonComponents/store/content/Content.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAEM;EACN,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAA;AAKc;EACE,YAAA;AAHhB;AAIgB;EACE,aAAA;AAFlB;AAIgB;EACE,8BAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;AAFlB;AAGkB;EACE,WAAA;EACA,oBAAA;AADpB;AAKoB;EACH,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAHjB;AAWE;EACI,SAAA;EACA,cAAA;EACA,aAAA;AATN","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  .top-section{\n    .left-side{\n      .title{\nfont-size: 20px;\nfont-weight: 600;\nline-height: 30px;\ncolor: #000000;\n      }\n    }\n      .right-side{\n          .search-container {\n              .ant-input-wrapper{\n                width: 276px;\n                .ant-input-group-addon{\n                  display: none;\n                }\n                .ant-input-affix-wrapper{\n                  border-radius: none !important;\n                  border-radius: 6px;\n                  border: 1px solid #DBDBDB;\n                  background-color: #FFFFFF;\n                  img{\n                    width: 20px;\n                    margin-right: 0.5rem;\n                  }\n                  .ant-input{\n                  \n                    &::placeholder{\n                 font-size: 14px;\n                 font-weight: 400;\n                 line-height: 21px;\n                 color: #787486;\n                    }\n                     }\n                }\n              } \n            }              \n      }\n  }\n  .bottom-section{\n      flex: 1;\n      overflow: auto;\n      display: flex;\n  }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
