import React from 'react'
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className='not-found-section w-full flex flex-column align-items-center justify-content-center'>
      404 
      <div>Page Not Found</div>
    </div>
  )
}

export default NotFound

