// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section {
  background-color: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #F1F1F1 inset;
}
.header-section .right-side .custom-dropdown-trigger {
  background-color: transparent;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/CommonComponents/store/header/header.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,0CAAA;AACJ;AACQ;EACI,6BAAA;EACA,YAAA;AACZ","sourcesContent":[".header-section{\n    background-color: #FFFFFF;\n    box-shadow: 0px -1px 0px 0px #F1F1F1 inset;\n    .right-side{\n        .custom-dropdown-trigger{\n            background-color: transparent;\n            border: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
