
import React, { createContext, useState, useEffect } from "react";
import * as postServices from "../services/post";
import api from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || null
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || null
  );
  const [partnerType, setPartnerType] = useState(
    localStorage.getItem("partnerType") || null  // Add userType
  );

  useEffect(() => {
    if (authToken) {
      localStorage.setItem("authToken", authToken);
      api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    } else {
      localStorage.removeItem("authToken");
      delete api.defaults.headers.common["Authorization"];
    }
  }, [authToken]);

  const handleLogin = async (email, password) => {
    try {
      const response = await postServices.login(email, password);
      console.log(response);
      setAuthToken(response.access_token);
      setRefreshToken(response.refresh_token);
      setPartnerType(response.partner_type);  // Store userType
      localStorage.setItem("refreshToken", response.refresh_token);
      localStorage.setItem("partnerType", response.partner_type);  // Persist userType in localStorage
      return response;
    } catch (error) {
      console.error("Failed to login:", error);
      throw error;
    }
  };

  const handleLogout = () => {
    setAuthToken(null);
    setRefreshToken(null);
    setPartnerType(null);  // Clear userType
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("partnerType");  // Remove userType from localStorage
    delete api.defaults.headers.common["Authorization"];
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
        refreshToken,
        setRefreshToken,
        partnerType,  // Pass partnerType through context
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
