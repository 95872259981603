import React from "react";
import "./header.scss";
import { Layout, Dropdown, Space } from "antd";
import logo from "../../../assets/images/logo.svg";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import avatarImg from "../../../assets/images/avatar.jpg";
import dropdownarrow from "../../../assets/images/dropdown-arrow.svg";
import { handleLogout } from "../../../services/auth";

const { Header } = Layout;
const items = [
  {
    key: '1',
    label: (
      <div onClick={() => handleLogout()}>
        Logout
      </div>
    ),
  }]

export const CustomHeader = () => {
  return (
    <Header className="header-section flex align-items-center justify-content-between">
      <img src={logo} alt="logo" />
      <div className="right-side flex align-items-center gap-5">
        <div className="notification-section">
          <i
            className="pi pi-bell p-overlay-badge"
            style={{ fontSize: "15.5px" }}
          >
            <Badge severity="danger"></Badge>
          </i>
        </div>
        <div style={{cursor: "pointer"}}>
        <Dropdown
    menu={{
      items
    }}
  >
    <a onClick={(e) => e.preventDefault()} className="custom-dropdown-trigger user-section flex gap-2 ">
      <Avatar image={avatarImg} shape="circle"  />
      <img src={dropdownarrow} alt="dropdown-arrow" />
    </a>
  </Dropdown>
          {/* <Avatar image={avatarImg} shape="circle" />
          <img src={dropdownarrow} alt="dropdown-arrow" /> */}
        </div>
      </div>
    </Header>
  );
};
