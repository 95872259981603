

import React, { useContext, useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import "./TallyOrder.scss";
import { Avatar, Table } from "antd";
import QuantityInput from "../../CommonComponents/QuantityInput/QuantityInput";  // Updated import
import { CustomButton as Button } from "../../CommonComponents/button/Button";
import { OrderContext } from "../../Context/OrderContext";

const TallyOrder = ({ item, onItemUpdate }) => {
  const { generateLabel } = useContext(OrderContext);
  const [dataSource, setDataSource] = useState([]);
  const [comment, setComment] = useState("");
  const [showCommentButtons, setShowCommentButtons] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(true);

  useEffect(() => {
    const initialData = item.orderItems.map((orderItem) => ({
      ...orderItem,
      quantity_approved: orderItem.quantity_approved || 0, // Default to 0
    }));
    setDataSource(initialData);
  }, [item.orderItems]);
  

  const handleQuantityChange = (id, newValue) => {
    const updatedDataSource = dataSource.map((orderItem) =>
      orderItem.id === id
        ? { ...orderItem, quantity_approved: newValue }
        : orderItem
    );
    setDataSource(updatedDataSource);
    onItemUpdate({ ...item, orderItems: updatedDataSource });
  };

  const columns = [
    {
      title: <span className="table-heading">Items Name</span>,
      dataIndex: ["item", "name"],
      key: "name",
    },
    {
      title: <span className="table-heading">Quantity (pcs) </span>,
      children: [
        {
          title: <span className="table-sub-heading">Order Placed</span>,
          dataIndex: "quantity_ordered",
          key: "quantity_ordered",
        },
        {
          title: <span className="table-sub-heading">Order Fulfilled</span>,
          dataIndex: "quantity_approved",
          key: "quantity_approved",
          render: (text, record) => (
            <QuantityInput
              item={record}
            selectedQuantity={record.quantity_approved}
              onQuantityChange={handleQuantityChange}
              isValid={record.quantity_approved <= record.quantity_ordered}
            />
          ),
        },
      ],
    },
    {
      title: <span className="table-heading">Price</span>,
      children: [
        {
          title: <span className="table-sub-heading">Order Placed</span>,
          key: "price",
          render: (text, record) => {
            const totalPrice =
              record.quantity_ordered * parseFloat(record.price_per_item);
            return `Rs. ${totalPrice.toFixed(2)}`;
          },
        },
        {
          title: <span className="table-sub-heading">Order Fulfilled</span>,
          key: "price",
          render: (text, record) => {
            const totalPrice =
              (record.quantity_approved || 0) * parseFloat(record.price_per_item);
            return `Rs. ${totalPrice.toFixed(2)}`;
          },
        },
      ],
    }
  ];

  const Summary = ({ data }) => {
    const totalOrderedQuantity = data.reduce(
      (acc, item) => acc + (parseInt(item.quantity_ordered) || 0),
      0
    );
    const totalOrderedPrice = data.reduce((acc, item) => {
      const price = parseFloat(item.price_per_item);
      const quantity = parseInt(item.quantity_ordered) || 0;
      return acc + price * quantity;
    }, 0);

    const totalApprovedQuantity = data.reduce(
      (acc, item) => acc + (parseInt(item.quantity_approved) || 0),
      0
    );
    const totalApprovedPrice = data.reduce((acc, item) => {
      const price = parseFloat(item.price_per_item);
      const quantity = parseInt(item.quantity_approved) || 0;
      return acc + price * quantity;
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell>Total</Table.Summary.Cell>
        <Table.Summary.Cell className="total_quantity ordered">{`pcs ${totalOrderedQuantity}`}</Table.Summary.Cell>
        <Table.Summary.Cell className="total_quantity fulfilled">{`pcs ${totalApprovedQuantity}`}</Table.Summary.Cell>
        <Table.Summary.Cell className="total_price ordered">{`Rs. ${totalOrderedPrice.toFixed(
          2
        )}`}</Table.Summary.Cell>
        <Table.Summary.Cell className="total_price fulfilled">{`Rs. ${totalApprovedPrice.toFixed(
          2
        )}`}</Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setShowCommentButtons(true);
  };

  const handleSaveComment = () => {
    setIsEditingComment(false);
    setShowCommentButtons(false);
  };

  const handleCancelComment = () => {
    setComment("");
    setShowCommentButtons(false);
  };

  const handleEditComment = () => {
    setIsEditingComment(true);
  };

  const handleRemoveComment = () => {
    setComment("");
    setIsEditingComment(true);
  };

  return (
    <div className="tally-order-section h-full flex flex-column gap-2">
      <div className="order_id">
        Order ID: <span>{item.id}</span>
      </div>
      <div className="table_section flex-1">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          summary={() => <Summary data={dataSource} />}
        />
      </div>
      <div className="comment_section">
        <div className="comment-heading pb-3">Note for this Order</div>
        <div className="comment_container flex flex-column gap-3">
          <div className="comment_content flex gap-2">
            <Avatar size={14}>{generateLabel(item?.partner?.name)}</Avatar>
            <div className="comment flex flex-column gap-1">
              <div className="client_name">{item?.partner?.name}</div>
              <div className="comment-text">{item?.comment}</div>
            </div>
          </div>

          <div className="comment_content flex gap-2">
            <Avatar size={14}>{generateLabel("Rishi Khanna")}</Avatar>

            {isEditingComment ? (
              <div className="text-area-container flex-1 flex flex-column">
                <InputTextarea
                  id="comment"
                  autoResize
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder="Add Note"
                />
                {showCommentButtons && (
                  <div className="comment-buttons align-self-end flex gap-2 mt-2">
                    <Button
                      customBtnClass="btn secondaryBtn"
                      onClick={handleCancelComment}
                    >
                      {" "}
                      Cancel
                    </Button>
                    <Button
                      customBtnClass="btn primaryBtn"
                      onClick={handleSaveComment}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="comment flex flex-column gap-1">
                <div className="client_name">Rishi Khanna</div>
                <div className="comment-text">{comment}</div>
                {comment && !isEditingComment && (
                  <div className="edit-delete-buttons flex align-items-center gap-2">
                    <div className="edit-button" onClick={handleEditComment}>
                      Edit
                    </div>
                    <div
                      className="delete-button"
                      onClick={handleRemoveComment}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TallyOrder;


