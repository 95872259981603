import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import "./DeliveryDetails.scss";

const DeliveryDetails = ({ form, onSaveDeliveryDetails, item }) => {
    useEffect(() => {
        // Set initial values when the modalItem changes or when the form is loaded
        form.setFieldsValue({
          handedTo: item?.handedTo || '',
          deliveredTime: item?.deliveredTime || '',
        });
      }, [item, form]);

  const onFinish = (values) => { 
    onSaveDeliveryDetails(values); 
  };

  return (
    <div className='delivery-details-section'>
      <div className="title">Confirmed Delivery</div>
      <Form
        form={form}
        name="delivery_form"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className='form-container flex gap-4'>
          <Form.Item
            name="handedTo"
            label="Handed to"
            rules={[{ required: true, message: 'Please input the name of the person handed to' }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="deliveredTime"
            label="Delivered time"
            rules={[{ required: true, message: 'Please input the delivery time' }]}
          >
            <Input placeholder="Enter delivery time" />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default DeliveryDetails;
