import React, { useEffect, useState } from "react";
import { Menu, Layout, Divider } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import dashboard_icon from "../../../assets/images/dashboard_icon.svg";
import dashboard_active_icon from "../../../assets/images/dashboard_active_icon.svg";
import order_management_icon from "../../../assets/images/order_management_icon.svg";
import order_management_active_icon from "../../../assets/images/order_management_active_icon.svg";
import kitchen_management_icon from "../../../assets/images/kitchen_management_icon.svg";
import kitchen_management_active_icon from "../../../assets/images/kitchen_management_active_icon.svg";
import partner_management_icon from "../../../assets/images/partner-management-icon.svg";
import partner_management_active_icon from "../../../assets/images/partner-management-active-icon.svg";
import cart_staff_management_icon from "../../../assets/images/cart_staff_management_icon.svg";
import cart_staff_management_active_icon from "../../../assets/images/cart_staff_management_active_icon.svg";
import fleet_management_icon from "../../../assets/images/fleet_management_icon.svg";
import fleet_management_active_icon from "../../../assets/images/fleet_management_active_icon.svg";
import csm_icon from "../../../assets/images//csm_icon.svg";
import csm_active_icon from "../../../assets/images/csm_active_icon.svg";
import invoice_icon from "../../../assets/images/invoice_icon.svg";
import invoice_active_icon from "../../../assets/images/invoice_active_icon.svg";
import setting_icon from "../../../assets/images/setting_icon.svg";
import help_icon from "../../../assets/images/help_icon.svg";
import custom_expand_icon from "../../../assets/images/submenu_expand_icon.svg"; 
import custom_collapse_icon from "../../../assets/images/submenu_collapsed_icon.svg"; 
import chevron_forward from "../../../assets/images/chevron_forward.svg";
import chevron_backword from "../../../assets/images/chevron_backword.svg";
import "./sider.scss";

const { Sider } = Layout;

export const CustomSider = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState("/admin-dashboard");
  const navigate = useNavigate();
  const location = useLocation(); // Track the current route path

  // Update activeMenu state based on the current route
  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]);

  // Function to handle screen resizing
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsSidebarActive(true); // Collapse sidebar on mobile view
    } else {
      setIsSidebarActive(false); // Expand sidebar on desktop view
    }
  };

  // Run handleResize on component mount and listen for window resize
  useEffect(() => {
    handleResize(); // Ensure the sidebar is collapsed if the screen width is small initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    navigate(menu);
  };

  return (
    <Sider
      collapsed={isSidebarActive}
      onCollapse={toggleSidebar}
      className={`sidebar ${isSidebarActive ? "collapsed" : ""}`}
      width={274}
      style={{ background: "#fff" }}
      breakpoint="md" // This will automatically collapse when window size <= 768px
      collapsedWidth={80} // Optionally set the collapsed width
    >
      <div className="top-header py-3 px-2  gap-2">
        <div className="tmm-store flex align-items-center justify-content-center">
          TMM
        </div>
        {!isSidebarActive && <div className="text">THE MOMO MAFIA STORE</div>}
        <button onClick={toggleSidebar} className="collapse-button">
          {isSidebarActive ? (
            <div className="collapsedBtn">
              <img src={chevron_forward} alt="chevron_forward" />
            </div>
          ) : (
            <div className="noncollapsedbtn">
              <img src={chevron_backword} alt="chevron_backword" />
            </div>
          )}
        </button>
      </div>

      <div className="menu-container">
        <Menu
          className="top-menu-container"
          mode="inline"
          defaultOpenKeys={['order-management']}
          selectedKeys={[activeMenu]} // Set the selected key based on activeMenu state
          onClick={(e) => handleMenuClick(e.key)}
        >
          <Menu.Item
            key="/admin-dashboard"
            icon={
              <img
                src={
                  activeMenu === "/admin-dashboard"
                    ? dashboard_active_icon
                    : dashboard_icon
                }
                alt="Dashboard"
              />
            }
            className="menu-item"
          >
            <span className="text">Dashboard</span>
          </Menu.Item>
          <Menu.SubMenu
            key="order-management"
            icon={
              <img
                src={
                  activeMenu.includes("order-management")
                    ? order_management_active_icon
                    : order_management_icon
                }
                alt="order-management"
              />
            }
            title="Order-Management"
            className="menu-item"
            expandIcon={({ isOpen }) =>
              isOpen ? (
                <img src={custom_expand_icon} alt="expand" className="submenu_arrow_icon" />
              ) : (
                <img src={custom_collapse_icon} alt="collapse" className="submenu_arrow_icon" />
              )
            }
          >
            <Menu.Item key="/order-management/active-orders">
              Active Orders
            </Menu.Item>
            <Menu.Item key="/order-management/all-orders">
              All orders
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="partner-management"
            icon={
              <img
                src={
                  activeMenu.includes("partner-management")
                    ? partner_management_active_icon
                    : partner_management_icon
                }
                alt="partner-management"
              />
            }
            title="partner-Management"
            className="menu-item"
            expandIcon={({ isOpen }) =>
              isOpen ? (
                <img src={custom_expand_icon} alt="expand" className="submenu_arrow_icon" />
              ) : (
                
                <img src={custom_collapse_icon} alt="collapse" className="submenu_arrow_icon" />
              )
            }
          ></Menu.SubMenu>
          <Menu.SubMenu
            key="kitchen-management"
            icon={
              <img
                src={
                  activeMenu.includes("kitchen-management")
                    ? kitchen_management_active_icon
                    : kitchen_management_icon
                }
                alt="kitchen-management"
              />
            }
            title="Kitchen-Management"
            className="menu-item"
            expandIcon={({ isOpen }) =>
              isOpen ? (
                <img src={custom_expand_icon} alt="expand" className="submenu_arrow_icon" />
              ) : (
                
                <img src={custom_collapse_icon} alt="collapse" className="submenu_arrow_icon" />
              )
            }
          ></Menu.SubMenu>
          <Menu.SubMenu
            key="cart-staff-management"
            icon={
              <img
                src={
                  activeMenu.includes("cart-staff-management")
                    ? cart_staff_management_active_icon
                    : cart_staff_management_icon
                }
                alt="cart-staff-management"
              />
            }
            title="Cart-Staff-Management"
            className="menu-item"
            expandIcon={({ isOpen }) =>
              isOpen ? (
                <img src={custom_expand_icon} alt="expand" className="submenu_arrow_icon" />
              ) : (
                
                <img src={custom_collapse_icon} alt="collapse" className="submenu_arrow_icon" />
              )
            }
          ></Menu.SubMenu>
          <Menu.SubMenu
            key="fleet-management"
            icon={
              <img
                src={
                  activeMenu.includes("fleet-management")
                    ? fleet_management_active_icon
                    : fleet_management_icon
                }
                alt="fleet-management"
              />
            }
            title="Fleet-Management"
            className="menu-item"
            expandIcon={({ isOpen }) =>
              isOpen ? (
                <img src={custom_expand_icon} alt="expand" className="submenu_arrow_icon" />
              ) : (
                
                <img src={custom_collapse_icon} alt="collapse" className="submenu_arrow_icon" />
              )
            }
          ></Menu.SubMenu>
          <Menu.Item
            key="/invoice"
            icon={
              <img
                src={
                  activeMenu === "/invoice" ? invoice_active_icon : invoice_icon
                }
                alt="invoice"
              />
            }
            className="menu-item"
          >
            <span className="text">Invoice</span>
          </Menu.Item>
          <Menu.Item
            key="/csm"
            icon={
              <img
                src={activeMenu === "/csm" ? csm_active_icon : csm_icon}
                alt="csm"
              />
            }
            className="menu-item"
          >
            <span className="text">CSM</span>
          </Menu.Item>
        </Menu>
        <Divider className="m-0 mt-2" />
       <Menu
          className="bottom-menu-container"
          mode="inline"
          selectedKeys={[activeMenu]}
          onClick={(e) => handleMenuClick(e.key)}
        >
          <Menu.Item
            key="/setting"
            icon={<img src={setting_icon} alt="setting" />}
            className="menu-item"
          >
            <span className="text">Setting</span>
          </Menu.Item>
          <Menu.Item
            key="/help-center"
            icon={<img src={help_icon} alt="help" />}
            className="menu-item"
          >
            <span className="text">Help center</span>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};
