import React from 'react'

const ViewChat = ({item}) => {
    console.log(item.orderId)
  return (
    <div>
      {item.orderId}
    </div>
  )
}

export default ViewChat
