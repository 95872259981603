
import api from './api';

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    if (response.data === 'Invalid credentials') {
      throw new Error('Invalid credentials');
    }
    return response.data;
  } catch (error) {
    console.error('Failed to login:', error);
    throw new Error('Failed to login');
  }
};

export const placeOrder = async (orderDetails) => {
  try {
    const response = await api.post('/orders', orderDetails);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw new Error('Failed to place order');
  }
};

export const addAddress = async (addressDetails) => {
  try {
    const response = await api.post('/address', addressDetails);
    return response.data;
  } catch (error) {
    console.error('Error adding address:', error);
    throw new Error('Failed to add address');
  }
}
