// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

.timeline-content {
  width: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #2d3748;
  white-space: nowrap;
}

.inner-circle {
  align-self: center;
  border-radius: 50%;
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-connector {
  position: absolute;
  top: 0.8rem;
  height: 2px;
  background-color: #3B9243;
}

@media (min-width: 768px) {
  .timeline-step:not(:last-child) .timeline-connector {
    content: "";
    display: inline;
    width: 108%;
    left: calc(50% + 0.8rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/Modal/viewtimeLine/ViewTimeLine.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACQ,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AADV;;AAIA;EACE,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AADF;;AAIA;EACE;IACE,WAAA;IACI,eAAA;IACA,WAAA;IACA,wBAAA;EADN;AACF","sourcesContent":["\n\n.timeline-container {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.timeline-steps {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  position: relative;\n}\n\n.timeline-step {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  margin: 1rem;\n}\n\n.timeline-content {\n  width: 10rem;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n          font-weight: 400;\n          line-height: 18px;\n          text-align: center;\n          color: #2d3748;\n          white-space: nowrap;\n}\n\n.inner-circle {\n  align-self: center;\n  border-radius: 50%;\n  height: 1.8rem;\n  width: 1.8rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.timeline-connector {\n  position: absolute;\n  top: 0.8rem;\n  height: 2px;\n  background-color: #3B9243;\n}\n\n@media (min-width: 768px) {\n  .timeline-step:not(:last-child) .timeline-connector {\n    content: \"\";\n        display: inline;\n        width: 108%;\n        left: calc(50% + 0.8rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
