import React, { useEffect, useState } from "react";
import { Form, Select, Button, Table, Checkbox } from "antd";
import "./PackagingOrder.scss";
import delete_icon from "../../assets/images/delete_icon.svg";

const { Option } = Select;

const generateWeightOptions = () => {
  const options = [];
  for (let i = 1; i <= 50; i++) {
    options.push({ value: `${i} kg`, label: `${i} kg` });
  }
  return options;
};

const weightOptions = generateWeightOptions();

const PackagingOrder = ({ item, onPackagingDataUpdate }) => {
  
  const [boxList, setBoxList] = useState([{ weight: null }]);
  const [confirmations, setConfirmations] = useState(item.orderItems.map(() => false));

  useEffect(() => {
    onPackagingDataUpdate(confirmations, boxList); // Update parent component with latest boxList and confirmations
  }, [confirmations, boxList]);

  const handleBoxAdd = () => {
    setBoxList([...boxList, { weight: null }]);
  };

  const handleBoxRemove = (index) => {
    const updatedBoxList = [...boxList];
    updatedBoxList.splice(index, 1);
    setBoxList(updatedBoxList);
  };

  const handleBoxChange = (value, index) => {
    const updatedBoxList = [...boxList];
    updatedBoxList[index].weight = value;
    setBoxList(updatedBoxList);
  };

  const handleConfirmChange = (checked, index) => {
    const updatedConfirmations = [...confirmations];
    updatedConfirmations[index] = checked;
    setConfirmations(updatedConfirmations);
  };


  const columns = [
    {
      title: <span className="table-heading">Items Name</span>,
      dataIndex: ['item', 'name'],
      key: 'name',
    },
    {
      title: <span className="table-heading">Order Fulfilled</span>,
      children: [
        {
          title: <span className="table-sub-heading">Quantity (pcs)</span>,
          dataIndex: 'quantity_approved',
          key: 'quantity_approved',
        },
        {
          title: <span className="table-sub-heading">Price</span>,
          key: 'price',
          render: (text, record) => {
            const totalPrice = record.quantity_approved * parseFloat(record.price_per_item);
            return `Rs. ${totalPrice}`;
          },
        },
        {
          title: <span className="table-sub-heading">Check & Confirm</span>,
          key: 'check_confirm',
          render: (text, record, index) => (
            <Checkbox
              checked={confirmations[index]}
              onChange={(e) => handleConfirmChange(e.target.checked, index)}
            />
          ),
        },
      ],
    },
  ];

  const Summary = ({ data }) => {
    const totalQuantity = data.reduce((acc, item) => acc + item.quantity_approved, 0);
    const totalPrice = data.reduce((acc, item) => {
      const price = parseFloat(item.price_per_item);
      return acc + price * item.quantity_approved;
    }, 0);

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell>Item Total</Table.Summary.Cell>
        <Table.Summary.Cell className="total_quantity">{`pcs ${totalQuantity}`}</Table.Summary.Cell>
        <Table.Summary.Cell className="total_price">{`Rs. ${totalPrice}`}</Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <div className="packaging-order-section flex flex-column">
      <div className="order_id">Order ID: <span>{item.orderId}</span></div>

      <div className="table_section flex-1">
        <Table
          dataSource={item.orderItems}
          columns={columns}
          pagination={false}
          summary={() => <Summary data={item.orderItems} />}
        />
      </div>

      <div className="box-container">
        <div className="label py-3">No. of Boxes & weight</div>
        <Form layout="vertical" className="p-3">
          <Form.Item>
            {boxList.map((singleBox, index) => (
              <div key={index} className="boxes flex flex-column mb-3">
                <label className="text mb-2">Box {index + 1} weight</label>
                <div className="flex align-items-center gap-2">
                  <Select
                    name="box"
                    id="box"
                    required
                    placeholder="Select"
                    value={singleBox.weight}
                    onChange={(value) => handleBoxChange(value, index)}
                    style={{ height: "36px", width: "50%" }}
                  >
                    {weightOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  {boxList.length > 1 && (
                    <div className="remove_btn flex align-items-center justify-content-center">
                      <img
                        src={delete_icon}
                        alt="delete_icon"
                        onClick={() => handleBoxRemove(index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <Button type="link" onClick={handleBoxAdd} className="add_btn">
              + Add more boxes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PackagingOrder;
