import api from "./api";

export const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem("refreshToken");
    delete api.defaults.headers.common["Authorization"];
    // Clear other sensitive data if needed
    localStorage.removeItem('selectedQuantities');
    localStorage.removeItem('totalPrice');
    localStorage.removeItem('cartItems');
    window.location.href = '/login'; // Redirect to login page or appropriate page
  };
  