import React, { useContext, useEffect, useState } from "react";
import "./SelectAddress.scss";
import { Button, Card, Radio, Space, Divider, message } from "antd";
import { OrderContext } from "../../Context/OrderContext";

const SelectAddress = ({ item, onItemUpdate }) => {
  const { openAddAddressModal } = useContext(OrderContext);
  const [addressData, setAddressData] = useState(item.addressData || []);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    const defaultIndex = addressData.findIndex(address => address.selectedAddress);
    if (defaultIndex !== -1) {
      setSelectedAddress(defaultIndex);
    }
  }, [addressData]);


  useEffect(() => {
    onItemUpdate({ ...item, selectedAddress });
  }, [selectedAddress, addressData]);

  const handleRemoveAddress = (index) => {
    const updatedAddressData = addressData.filter((_, i) => i !== index);
    if (index === selectedAddress) {
      setSelectedAddress(null);
    } else if (index < selectedAddress) {
      setSelectedAddress(prev => prev - 1);
    }
    setAddressData(updatedAddressData);
    onItemUpdate({ ...item, addressData: updatedAddressData, selectedAddress });
  };

  const handleSetDefaultAddress = (index) => {
    const updatedAddressData = addressData.map((address, i) => ({
      ...address,
      default_address: i === index,
    }));
    setAddressData(updatedAddressData);
    onItemUpdate({ ...item, addressData: updatedAddressData});
    message.success("Default Address Set successfully");
  };

  const handleEditAddress = (index) => {
    const addressToEdit = addressData[index];
    openAddAddressModal({ ...item, addressToEdit, isEditing: true, editIndex: index });
  };

  const handleAddNewAddress = () => {
    openAddAddressModal({ ...item, isEditing: false });
  };

  const handleAddressSelect = (index) => {
    const updatedAddressData = addressData.map((address, i) => ({
      ...address,
      selectedAddress: i === index,
    }));
    setAddressData(updatedAddressData);
    setSelectedAddress(index);
  };

  return (
    <div className="select-address-section">
      <div className="title">Select an address</div>
      <div className="add_address" onClick={handleAddNewAddress}>
        <div className="add_address_btn">+ Add Address</div>
      </div>
      <Divider plain>Saved addresses</Divider>
      <Space direction="vertical" className="card-container" style={{ width: "100%" }}>
        {addressData.map((address, index) => (
          <Card key={index} className="card">
            <Space direction="vertical" style={{ width: "100%" }}>
              <div className="flex align-items-center justify-content-between">
                <Space>
                  <Radio
                    checked={address.selectedAddress}
                    onChange={() => handleAddressSelect(index)}
                  />
                  <div className="cart_name">{address.save_as}</div>
                </Space>
                {address.default_address && <div className="default_address_icon">Default Address</div>}
              </div>
              <div>
                <div className="full_name">{address.full_name}</div>
                <div className="address">{`${address.address_line1}, ${address.address_line2}, ${address.town_city}, ${address.pincode}`}</div>
              </div>
              <Space>
                <Button onClick={() => handleEditAddress(index)}>Edit</Button>
                <Button onClick={() => handleRemoveAddress(index)}>Remove</Button>
                {!address.default_address && (
                  <Button onClick={() => handleSetDefaultAddress(index)}>Save as Default</Button>
                )}
              </Space>
            </Space>
          </Card>
        ))}
      </Space>
    </div>
  );
};

export default SelectAddress;
