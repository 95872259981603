


import React, { useState } from 'react';
import { Input } from "antd";
import "./Content.scss";
import { Layout } from 'antd';
import Routers from '../../../Routers/Routers';
import { CustomBreadcrumb as Breadcrumb } from '../breadcrumb/Breadcrumb';
import { CustomButton as Button } from '../button/CustomButton';
import search_icon from "../../../assets/images/search-normal.svg";
import replay_icon from "../../../assets/images/replay_icon.svg";

export const CustomContent = () => {
  const { Content } = Layout;
  const { Search } = Input;


  //     const onSearch = (value) => {
//    //   const searchTerm = value.toLowerCase();
//   //   const filteredOrders = orders.filter(
//   //     (order) =>
//   //       order.orderId.toLowerCase().includes(searchTerm) ||
//   //       order.client_name.toLowerCase().includes(searchTerm) ||
//   //       order.location.toLowerCase().includes(searchTerm)
//   //   );
//   //   setOrders(filteredOrders);
//   };


  // State for the title
  const [title, setTitle] = useState("");
  console.log(title);

  const onSearch = (value) => {
    // Search logic here
  };

  const handleRefreahButton = () => {
    window.location.reload();
  }

  return (
    <Content className="content">
      <div className="top-section flex align-items-center justify-content-between px-3 pt-3">
        <div className="left-side">
        <Breadcrumb setTitle={setTitle} />
        <div className='title'>{title}</div>
        </div>
        <div className="right-side flex align-items-center gap-3">
          <div className="search-container">
            <Search
              placeholder="Name, order ID, location"
              onSearch={onSearch}
              prefix={<img src={search_icon} alt="search" />}
            />
          </div>
          {title === "Active Orders" ? (
            <>
            <Button customBtnClass="Btn firstBtn">Tally at once</Button>
            <Button customBtnClass="Btn secondBtn">+ Add Order</Button>
            </>
          ) : 
          title === "Stock Management" ? (
            <>
            <Button customBtnClass="Btn secondBtn flex align-items-center gap-2" onClick={handleRefreahButton}>
              <img src={replay_icon} alt='replay_icon' />
              Refresh</Button>
            </>
          ) : ""}
        </div>
      </div>
      <div className="bottom-section p-3">
        <Routers />
      </div>
    </Content>
  );
};