// import React, { useContext, useState, useRef } from 'react';
// import { Form } from 'antd';
// import { Toast } from 'primereact/toast';
// import { Context } from '../Context/AppContext';
// import TallyOrder from '../Components/tallyOrder/TallyOrder';
// import ViewOrder from '../Components/viewOrder/ViewOrder';
// import { CustomButton as Button } from '../CommonComponents/button/Button';
// import DynamicModal from './DynamicModal/DynamicModal';
// import PackagingOrder from '../Components/packagingOrder/PackagingOrder';
// import DispatchOrder from '../Components/dispatchOrder/DispatchOrder';
// import ViewPackaging from '../Components/viewPackaging/ViewPackaging';
// import ViewDispatch from '../Components/viewDispatch/ViewDispatch';
// import SelectAddress from '../Components/selectAddress/SelectAddress';
// import AddAddress from '../Components/addAddress/AddAddress';
// import ViewTimeLine from '../Components/viewtimeLine/ViewTimeLine';
// import ViewChat from '../Components/viewChat/ViewChat';
// import checkIcon from "../assets/images/CheckCircle.svg";

// const ModalContainer = () => {
//   const { setOrders, isModalOpen, setIsModalOpen, modalType, modalTitle, modalItem, setModalItem, openTallyModal, openPackagingModal, openStartPackagingModal, openDispatchModal,openOrderDispatchModal, openSelectAddressModal,openTimeLineModal, updateOrderStatus } = useContext(Context);


//   const [boxList, setBoxList] = useState([]);
//   const [confirmations, setConfirmations] = useState([]);
//   const [selectedAddress, setSelectedAddress] = useState(null);
//   const [form] = Form.useForm();
//   const toastRef = useRef(null);

//   const handleUpdateTallyData = (updatedItem) => {
//     setModalItem(updatedItem);
//   };

//   const handleMoveToPackaging = () => {
//     const updatedModalItem = {
//       ...modalItem,
//       orderItems: modalItem.orderItems.map(item => ({
//         ...item,
//         quantity_approved: item.quantity_approved || item.quantity_ordered,
//       })),
//     };
//     setModalItem(updatedModalItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedModalItem?.id ? updatedModalItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//     updateOrderStatus(updatedModalItem?.id, 'Packaging');
//     // openPackagingModal({ ...updatedModalItem, name: 'Packaging' });
//     toastRef.current.show({
//       severity: 'success',
//       detail: 'Order moved to package successfully',
//       life: 3000,
//       className: 'custom-success-toast',
//       icon: <img src={checkIcon} alt='success' />
//     });
//     setIsModalOpen(false);
//   };

//   const handleUpdatePackagingData = (updatedConfirmations, updatedBoxList) => {
//     setConfirmations(updatedConfirmations);
//     setBoxList(updatedBoxList);
//   };

//   const handleMoveToDispatch = () => {
//     if (confirmations.every(confirmed => !confirmed)) {
//       alert("Please select at least one item to confirm.");
//       return;
//     }

//     if (boxList.length === 0 || boxList.some(box => !box.weight)) {
//       alert("Please add and select the weight of at least one box.");
//       return;
//     }

//     const currentTime = new Date();
//     const updatedModalItem = {
//       ...modalItem,
//       packedAt: currentTime,
//       boxList,
//       orderItems: modalItem.orderItems.map((item, index) => ({
//         ...item,
//         confirmed: confirmations[index],
//       quantity_dispatch: confirmations[index] ? item.quantity_approved : item.quantity_dispatch,  // Set dispatch_quantity only if confirmed is true
//       })),
//     };
//     setModalItem(updatedModalItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedModalItem.orderId ? updatedModalItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//     updateOrderStatus(updatedModalItem.orderId, 'Dispatch');
//     // openDispatchModal({ ...updatedModalItem, name: 'Dispatch' });
//     toastRef.current.show({
//       severity: 'success',
//       detail: 'Order moved to dispatch successfully',
//       life: 3000,
//       className: 'custom-success-toast',
//       icon: <img src={checkIcon} alt='success' />
//     });
//     setIsModalOpen(false);
//   };

//   const handleDispatchOrder = () => {
//     const currentTime = new Date();
//     const updatedModalItem = {
//       ...modalItem,
//       dispatchedAt: currentTime
//     };
//     setModalItem(updatedModalItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedModalItem.orderId ? updatedModalItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//     openOrderDispatchModal(updatedModalItem);
//   }

// const handleOrderOutForDelivery = (details) => {
//   const currentTime = new Date();
//   const updatedModalItem = {
//     ...modalItem,
//     invoice: details.invoice,
//     transport: details.Transport,
//     deliveredAt: currentTime
//   };
//   setModalItem(updatedModalItem);
//   setOrders(prevOrders => {
//     const updatedOrders = prevOrders.map(order =>
//       order.orderId === updatedModalItem.orderId ? updatedModalItem : order
//     );
//     localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//     return updatedOrders;
//   });
//   updateOrderStatus(updatedModalItem.orderId, 'Out For Delivery');
//   // openTimeLineModal({ ...updatedModalItem, name: 'Out For Delivery' });
//   toastRef.current.show({
//     severity: 'success',
//     detail: 'Order move to out for delivery successfully',
//     life: 3000,
//     className: 'custom-success-toast',
//     icon: <img src={checkIcon} alt='success' />
//   });
//   setIsModalOpen(false);
// }
  

//   const handleSaveAddress = (address, editIndex = null) => {
//     let updatedAddressData;
    
//     if (editIndex !== null) {
//       updatedAddressData = [...modalItem.addressData];
//       updatedAddressData[editIndex] = address;
//     } else {
//       updatedAddressData = modalItem.addressData
//         ? [...modalItem.addressData, address]
//         : [address];
//     }
  
//     if (address.default_address) {
//       updatedAddressData = updatedAddressData.map(addr => ({
//         ...addr,
//         default_address: addr === address,
//       }));
//     }
  
//     const updatedModalItem = {
//       ...modalItem,
//       addressData: updatedAddressData,
//     };
//     setModalItem(updatedModalItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedModalItem.orderId ? updatedModalItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//     openSelectAddressModal(updatedModalItem);
//   };

//   const handleUpdateAddressData = (updatedItem) => {
//     setSelectedAddress(updatedItem.selectedAddress);
//     setModalItem(updatedItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedItem.orderId ? updatedItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//   };

//   const handleNextBtn = () => {
//     const updatedModalItem = {
//       ...modalItem,
//       addressData: modalItem.addressData.map((address,index) => ({
//         ...address,
//         selectedAddress: index === selectedAddress,
//       })),
//     };
//     setModalItem(updatedModalItem);
//     setOrders(prevOrders => {
//       const updatedOrders = prevOrders.map(order =>
//         order.orderId === updatedModalItem.orderId ? updatedModalItem : order
//       );
//       localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
//       return updatedOrders;
//     });
//  openOrderDispatchModal(updatedModalItem);
//   }


//   const handleGoBack = (previousModalType) => {
//     switch (previousModalType) {
//       case 'Select Address':
//         openSelectAddressModal(modalItem);
//         break;
//       case 'Dispatch Order':
//         openOrderDispatchModal(modalItem);
//         break;
//       default:
//         setIsModalOpen(false);
//     }
//   };


//   let modalContent = null;
//   let modalFooter = null;


//   if (modalType === "View Order") {
//     modalContent = <ViewOrder item={modalItem} />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={() => openTallyModal(modalItem)}>Tally</Button>
//       </div>
//     );
//   } else if (modalType === "Tally Order") {
//     modalContent = <TallyOrder item={modalItem} onItemUpdate={handleUpdateTallyData} />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={handleMoveToPackaging}>Move to Packaging</Button>
//       </div>
//     );
//   } else if (modalType === "View Packaging") {
//     modalContent = <ViewPackaging item={modalItem} />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={() => openStartPackagingModal(modalItem)}>Start Packaging</Button>
//       </div>
//     );
//   } else if (modalType === "Start Packaging") {
//     modalContent = (
//       <PackagingOrder
//         item={modalItem}
//         onPackagingDataUpdate={handleUpdatePackagingData}
//       />
//     );
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={handleMoveToDispatch}>Packaging Complete</Button>
//       </div>
//     );
//   } else if (modalType === "View Dispatch") {
//     modalContent = <ViewDispatch item={modalItem} />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={handleDispatchOrder}>Dispatch Order</Button>
//       </div>
//     );
//   } else if (modalType === "Dispatch Order") {
//     modalContent = <DispatchOrder item={modalItem} form={form} onSaveInvoiceDetail={handleOrderOutForDelivery}  />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => setIsModalOpen(false)}>Cancel</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={() => form.submit()}>Out For Delivery</Button>
//       </div>
//     ); 
//   } else if (modalType === "Select Address") {
//     modalContent = <SelectAddress item={modalItem} onItemUpdate={handleUpdateAddressData} />;
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => handleGoBack('Dispatch Order')}>Go Back</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={handleNextBtn}>Next</Button>
//       </div>
//     );
    
//   } else if (modalType === "Add Address") {
//     modalContent = <AddAddress item={modalItem} form={form} onSaveAddress={handleSaveAddress} />;  
//     modalFooter = (
//       <div className='footer-container flex align-items-center gap-2'>
//         <Button customBtnClass="btn secondaryBtn" onClick={() => handleGoBack('Select Address')}>Go Back</Button>
//         <Button customBtnClass="btn primaryBtn" onClick={() => form.submit()}>Save Address</Button>
//       </div>
//     );
//   } else if (modalType === "View TimeLine") {
//     modalContent = <ViewTimeLine item={modalItem} />;
//   } else if (modalType === "View Chat") {
//     modalContent = <ViewChat item={modalItem} />;
//   }

//   return (
   
//    <>
//     <Toast ref={toastRef} />
//     {isModalOpen && (
//       <DynamicModal
//         open={isModalOpen}
//         title={modalTitle}
//         closable={true}
//         close={() => setIsModalOpen(false)}
//         footer={modalFooter}
//         className={modalType === "View TimeLine" ? "viewTimeLineModal" : ""}
//       >
//         {modalContent}
//       </DynamicModal>
//     )}
//    </>
//   );
// };

// export default ModalContainer;



import React, { useContext, useState, useRef } from 'react';
import { Form } from 'antd';
import { Toast } from 'primereact/toast';
import TallyOrder from './tallyOrder/TallyOrder';
import ViewOrder from './viewOrder/ViewOrder';
import { CustomButton as Button } from "../CommonComponents/store/button/CustomButton";
import DynamicModal from './DynamicModal/DynamicModal';
import PackagingOrder from './packagingOrder/PackagingOrder';
import DispatchOrder from './dispatchOrder/DispatchOrder';
import ViewPackaging from './viewPackaging/ViewPackaging';
import ViewDispatch from './viewDispatch/ViewDispatch';
import SelectAddress from './selectAddress/SelectAddress';
import AddAddress from './addAddress/AddAddress';
import ViewTimeLine from './viewtimeLine/ViewTimeLine';
import ViewChat from './viewChat/ViewChat';
import checkIcon from "../assets/images/CheckCircle.svg";
import DeliveryDetails from './deliveryDetails/DeliveryDetails';
import { OrderContext } from '../Context/OrderContext';

const ModalContainer = () => {
  const { orders, setOrders, isModalOpen, setIsModalOpen, modalType, modalTitle, modalItem, setModalItem, openModal, updateOrderStatus } = useContext(OrderContext);

  const [boxList, setBoxList] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [form] = Form.useForm();
  const toastRef = useRef(null);

   const handleMoveToPackaging = () => {
    console.log(modalItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order?.id === modalItem?.id ? modalItem : order
      );
      console.log(modalItem?.id);
      return updatedOrders;
    });
    console.log(orders);
    updateOrderStatus(modalItem?.id, 25);
    toastRef.current.show({
      severity: 'success',
      detail: 'Order moved to package successfully',
      life: 3000,
      className: 'custom-success-toast',
      icon: <img src={checkIcon} alt='success' />
    });
    setIsModalOpen(false);
  };

console.log(orders);
  const handleUpdatePackagingData = (updatedConfirmations, updatedBoxList) => {
    setConfirmations(updatedConfirmations);
    setBoxList(updatedBoxList);
  };

  const handleMoveToDispatch = () => {
    if (confirmations.every(confirmed => !confirmed)) {
      alert("Please select at least one item to confirm.");
      return;
    }

    if (boxList.length === 0 || boxList.some(box => !box.weight)) {
      alert("Please add and select the weight of at least one box.");
      return;
    }

    const currentTime = new Date();
    const updatedModalItem = {
      ...modalItem,
      packedAt: currentTime,
      boxList,
      orderItems: modalItem.orderItems.map((item, index) => ({
        ...item,
        confirmed: confirmations[index],
      quantity_dispatch: confirmations[index] ? item.quantity_approved : item.quantity_dispatch,  // Set dispatch_quantity only if confirmed is true
      })),
    };
    setModalItem(updatedModalItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order.orderId === updatedModalItem.orderId ? updatedModalItem : order
      );
      localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
      return updatedOrders;
    });
    updateOrderStatus(updatedModalItem.orderId, 'Dispatch');
    // openDispatchModal({ ...updatedModalItem, name: 'Dispatch' });
    toastRef.current.show({
      severity: 'success',
      detail: 'Order moved to dispatch successfully',
      life: 3000,
      className: 'custom-success-toast',
      icon: <img src={checkIcon} alt='success' />
    });
    setIsModalOpen(false);
  };

  const handleDispatchOrder = () => {
    const currentTime = new Date();
    const updatedModalItem = {
      ...modalItem,
      dispatchedAt: currentTime
    };
    setModalItem(updatedModalItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order.orderId === updatedModalItem.orderId ? updatedModalItem : order
      );
      localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
      return updatedOrders;
    });
    // openOrderDispatchModal(updatedModalItem);
  }

const handleOrderOutForDelivery = (details) => {
  const currentTime = new Date();
  const updatedModalItem = {
    ...modalItem,
    invoice: details.invoice,
    transport: details.Transport,
    deliveredAt: currentTime
  };
  setModalItem(updatedModalItem);
  setOrders(prevOrders => {
    const updatedOrders = prevOrders.map(order =>
      order.orderId === updatedModalItem.orderId ? updatedModalItem : order
    );
    localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
    return updatedOrders;
  });
  updateOrderStatus(updatedModalItem.orderId, 'Out For Delivery');
  // openTimeLineModal({ ...updatedModalItem, name: 'Out For Delivery' });
  toastRef.current.show({
    severity: 'success',
    detail: 'Order move to out for delivery successfully',
    life: 3000,
    className: 'custom-success-toast',
    icon: <img src={checkIcon} alt='success' />
  });
  setIsModalOpen(false);
}
  

  const handleSaveAddress = (address, editIndex = null) => {
    let updatedAddressData;
    
    if (editIndex !== null) {
      updatedAddressData = [...modalItem.addressData];
      updatedAddressData[editIndex] = address;
    } else {
      updatedAddressData = modalItem.addressData
        ? [...modalItem.addressData, address]
        : [address];
    }
  
    if (address.default_address) {
      updatedAddressData = updatedAddressData.map(addr => ({
        ...addr,
        default_address: addr === address,
      }));
    }
  
    const updatedModalItem = {
      ...modalItem,
      addressData: updatedAddressData,
    };
    setModalItem(updatedModalItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order.orderId === updatedModalItem.orderId ? updatedModalItem : order
      );
      localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
      return updatedOrders;
    });
    // openSelectAddressModal(updatedModalItem);
  };

  const handleUpdateAddressData = (updatedItem) => {
    setSelectedAddress(updatedItem.selectedAddress);
    setModalItem(updatedItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order.orderId === updatedItem.orderId ? updatedItem : order
      );
      localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
      return updatedOrders;
    });
  };

  const handleNextBtn = () => {
    const updatedModalItem = {
      ...modalItem,
      addressData: modalItem.addressData.map((address,index) => ({
        ...address,
        selectedAddress: index === selectedAddress,
      })),
    };
    setModalItem(updatedModalItem);
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order =>
        order.orderId === updatedModalItem.orderId ? updatedModalItem : order
      );
      localStorage.setItem("ordersData", JSON.stringify(updatedOrders));
      return updatedOrders;
    });
//  openOrderDispatchModal(updatedModalItem);
  }


  const handleGoBack = (previousModalType) => {
    switch (previousModalType) {
      case 'Select Address':
        // openSelectAddressModal(modalItem);
        break;
      case 'Dispatch Order':
        // openOrderDispatchModal(modalItem);
        break;
      default:
        setIsModalOpen(false);
    }
  };

  const handleDeliveryDetails = (deliveryDetails) => {
    localStorage.setItem("Delivery Details", JSON.stringify(deliveryDetails));
    console.log(deliveryDetails);
    updateOrderStatus(modalItem?.id, 100);
    toastRef.current.show({
      severity: 'success',
      detail: 'Order Delivered successfully',
      life: 3000,
      className: 'custom-success-toast',
      icon: <img src={checkIcon} alt='success' />
    });
    setIsModalOpen(false);
  }

  // Reusable button rendering
  const renderModalFooter = (buttons) => (
    <div className='footer-container flex align-items-center gap-2'>
      {buttons.map((button, index) => (
        <Button key={index} customBtnClass={button.customBtnClass} onClick={button.onClick}>
          {button.label}
        </Button>
      ))}
    </div>
  );

  const modalContentMap = {
    // "View Order": {
    //   content: <ViewOrder item={modalItem} />,
    //   footer: renderModalFooter([
    //     { label: "Cancel", customBtnClass: "btn secondaryBtn", onClick: () => setIsModalOpen(false) },
    //     { label: "Tally", customBtnClass: "btn primaryBtn", onClick: () => openModal("Tally Order", "Tally Order", modalItem) }
    //   ]),
    // },
    "View Order": {
      content: <ViewOrder item={modalItem} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Confirm Deliverd", customBtnClass: "Btn firstBtn", onClick: () => openModal("Confirm Delivery", "Confirm Delivery", modalItem) }
      ]),
    },
    "Tally Order": {
      content: <TallyOrder item={modalItem} onItemUpdate={(updatedItem) => setModalItem(updatedItem)} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Move to Packaging", customBtnClass: "Btn firstBtn", onClick: handleMoveToPackaging }
      ]),
    },
    "View Packaging": {
      content: <ViewPackaging item={modalItem} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Start Packaging", customBtnClass: "Btn firstBtn", onClick: () => openModal("Start Packaging", "Start Packaging", modalItem) }
      ]),
    },
    "Start Packaging": {
      content: <PackagingOrder item={modalItem} onPackagingDataUpdate={handleUpdatePackagingData} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Packaging Complete", customBtnClass: "Btn firstBtn", onClick: handleMoveToDispatch }
      ]),
    },
    "View Dispatch": {
      content: <ViewDispatch item={modalItem} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Dispatch Order", customBtnClass: "Btn firstBtn", onClick: handleDispatchOrder }
      ]),
    },
    "Dispatch Order": {
      content: <DispatchOrder item={modalItem} form={form} onSaveInvoiceDetail={handleOrderOutForDelivery} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Out For Delivery", customBtnClass: "Btn firstBtn", onClick: () => form.submit() }
      ]),
    },
    "Select Address": {
      content: <SelectAddress item={modalItem} onItemUpdate={handleUpdateAddressData} />,
      footer: renderModalFooter([
        { label: "Go Back", customBtnClass: "Btn secondBtn", onClick: () => handleGoBack('Dispatch Order') },
        { label: "Next", customBtnClass: "Btn firstBtn", onClick: handleNextBtn }
      ]),
    },
    "Add Address": {
      content: <AddAddress item={modalItem} form={form} onSaveAddress={handleSaveAddress} />,
      footer: renderModalFooter([
        { label: "Go Back", customBtnClass: "Btn secondBtn", onClick: () => handleGoBack('Select Address') },
        { label: "Save Address", customBtnClass: "Btn firstBtn", onClick: () => form.submit() }
      ]),
    },
    "View TimeLine": {
      content: <ViewTimeLine item={modalItem} />,
      footer: null,
    },
    "View Chat": {
      content: <ViewChat item={modalItem} />,
      footer: null,
    },
    "Confirm Delivery": {
      content: <DeliveryDetails item={modalItem} form={form} onSaveDeliveryDetails={handleDeliveryDetails} />,
      footer: renderModalFooter([
        { label: "Cancel", customBtnClass: "Btn secondBtn", onClick: () => setIsModalOpen(false) },
        { label: "Deliverd", customBtnClass: "Btn firstBtn", onClick: () => form.submit() }
      ]),
    }
  };

  // Modal content and footer based on the modalType
  const { content: modalContent, footer: modalFooter } = modalContentMap[modalType] || {};

  return (
    <>
      <Toast ref={toastRef} />
      {isModalOpen && (
        <DynamicModal
          open={isModalOpen}
          title={modalTitle}
          closable={true}
          close={() => setIsModalOpen(false)}
          footer={modalFooter}
          className={modalType === "View TimeLine" ? "viewTimeLineModal" : "Confirm Delivery" ? "confirmDelivery" : ""}
        >
          {modalContent}
        </DynamicModal>
      )}
    </>
  );
};

export default ModalContainer;