import React, { createContext, useState } from "react";
import { Avatar } from "antd";
import * as putServices from "../services/put";

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [expandedCard, setExpandedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalItem, setModalItem] = useState(null);

  const generateLabel = (fullName) => {
    const nameParts = fullName.split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts[nameParts.length - 1] || "";
    return firstName.charAt(0) + lastName.charAt(0);
  };

  const handleCardClick = (orderId) => {
    setExpandedCard(expandedCard === orderId ? null : orderId);
  };

  const openModal = (type, title, item) => {
    setModalType(type);
    setModalTitle(
      <div className="modal-header flex flex-column gap-2">
        <div className="modal-title">{title}</div>
        <div className="flex align-items-center gap-2">
          <div className="client flex align-items-center gap-2">
            client:{" "}
            <span className="flex align-items-center gap-2">
              <Avatar className="avatar">
                {generateLabel(item?.partner?.name || item?.client_name)}
              </Avatar>
              {item?.partner?.name || item?.client_name}
            </span>
          </div>
          <div className="divider-line"></div>
          <div className="client flex align-items-center gap-2">
            location: <span>{item?.deliveryAddress?.city || item?.location}</span>
          </div>
        </div>
      </div>
    );
    setModalItem(item);
    setIsModalOpen(true);
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const data = await putServices.updateOrderStatusAPI(orderId, Number(newStatus));
      return data;
    } catch (error) {
      console.error('Failed to update order status:', error);
      throw error;
    }
  };

  return (
    <OrderContext.Provider
      value={{
        expandedCard,
        handleCardClick,
        isModalOpen,
        setIsModalOpen,
        modalType,
        modalTitle,
        modalItem,
        setModalItem,
        openModal,
        generateLabel,
        updateOrderStatus,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};