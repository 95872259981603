// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quantity-input-container {
  width: 92px;
}
.quantity-input-container .quantity-input-content {
  position: relative;
  width: 100%;
}
.quantity-input-container .quantity-input {
  width: 100%;
  padding: 0.2rem 0.5rem;
  border: 1px solid #276229;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  color: #276229;
}
.quantity-input-container .quantity-input::placeholder {
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  color: #898A8D;
}
.quantity-input-container .invalid {
  border: 1px solid red;
}
.quantity-input-container .clear-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #898A8D;
}
.quantity-input-container .clear-icon:hover {
  color: #276229;
}`, "",{"version":3,"sources":["webpack://./src/CommonComponents/QuantityInput/QuantityInput.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER;AACI;EACI,WAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACR;AACQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACZ;AAEI;EACI,qBAAA;AAAR;AAGI;EACI,kBAAA;EACA,aAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADR;AAII;EACI,cAAA;AAFR","sourcesContent":[".quantity-input-container {\n    width: 92px;\n    .quantity-input-content {\n        position: relative;\n        width: 100%;\n    }\n\n    .quantity-input {\n        width: 100%;\n        padding: 0.2rem 0.5rem;\n        border: 1px solid #276229;\n        border-radius: 4px;\n        font-size: 12px;\n        font-weight: 600;\n        line-height: 16.8px;\n        color: #276229;\n\n        &::placeholder {\n            font-size: 12px;\n            font-weight: 300;\n            line-height: 16.8px;\n            color: #898A8D;\n        }\n    }\n    .invalid {\n        border: 1px solid red;\n      }\n\n    .clear-icon {\n        position: absolute;\n        right: 0.5rem;\n        top: 50%;\n        transform: translateY(-50%);\n        cursor: pointer;\n        font-size: 1.2rem;\n        color: #898A8D;\n    }\n\n    .clear-icon:hover {\n        color: #276229;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
