
import React, { useContext, useState } from 'react';
import './LoginPage.scss';
import logo_basket from '../../assets/images/mafia_basket_top.svg';
import logo from '../../assets/images/green-full-logo.svg';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../Context/AuthContext';

const LoginPage = ({onLogin}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {handleLogin} = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Please input your email!'),
      password: Yup.string().required('Please input your password!'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await handleLogin(values.email, values.password);
        navigate('/');
      } catch (error) {
        console.error('Login failed:', error);
        setErrors({ submit: error.message || 'Login failed. Please check your credentials and try again.' });
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="login-section">
      <div className="login-container max-width flex flex-column justify-content-between py-6">
        <div className="top-section flex flex-column align-items-center">
          <div className="logo_basket">
            <img src={logo_basket} alt="logo_basket" />
          </div>
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="middle-section flex-grow flex flex-column gap-4">
          <div className="form_title">Your Business Space</div>
          <form onSubmit={formik.handleSubmit} className="flex flex-column align-items-center gap-4">
            <div className="p-field flex flex-column">
              <InputText
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.touched.email && formik.errors.email ? 'p-invalid' : ''}
              />
              {formik.touched.email && formik.errors.email ? (
                <small className="p-error">{formik.errors.email}</small>
              ) : null}
            </div>
            <div className="p-field flex flex-column password-field">
              <InputText
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.touched.password && formik.errors.password ? 'p-invalid' : ''}
              />
              <span
                className="password-toggle-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
              {formik.touched.password && formik.errors.password ? (
                <small className="p-error">{formik.errors.password}</small>
              ) : null}
            </div>
            <div className="p-field flex justify-content-center">
              <Button type="submit" label="LOG IN" className="" />
            </div>
            {formik.errors.submit && (
              <div className="p-error p-mt-2">{formik.errors.submit}</div>
            )}
          </form>
        </div>
        <div className="bottom-section">
          <div className="bottom_title">Forgot Username or Password?</div>
          <div className="call_text">
            Call . <a href="tel:7856867489">78568 67489</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
