// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-section {
  font-size: 2rem;
  font-weight: 800;
  color: #455A64;
}
.not-found-section div {
  font-size: 1rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NotFoundPage/NotFound.scss"],"names":[],"mappings":"AAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACA;AAAA;EACI,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".not-found-section{\nfont-size: 2rem;\nfont-weight: 800;\ncolor: #455A64;\ndiv{\n    font-size: 1rem;\n    font-weight: 600;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
