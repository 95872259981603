
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import "./Layout.scss";
import { Layout } from "antd";

import Routers from "../../Routers/Routers";

import { AuthContext } from "../../Context/AuthContext";

import { CustomHeader as Header } from "../store/header/Header";
import { CustomSider as Sider } from "../store/sider/Sider";
import { CustomContent as Content } from "../store/content/Content";
import ModalContainer from "../../Modal/ModalContainer";


export const CustomLayout = () => {
  const {partnerType, authToken } = useContext(AuthContext); // Assuming userType is stored in AppContext

  // Separate layout for client
  const CustomerLayout = () => (
      <Layout>
          <Routers />
      </Layout>
  );

  // Separate layout for partner
  const StoreLayout = () => (
    <Layout className="store-layout">
      <Header />
      <Layout>
        <Sider />
       <Content />
      </Layout>
      <ModalContainer />
    </Layout>
  );

  // Conditionally render based on userType
  if (!partnerType && !authToken) {
    return <Navigate to="/login" />; // Redirect to login if userType is not set
  }

  return partnerType === "customer" ? <CustomerLayout /> : <StoreLayout />;
};


