import React from "react";
import "./DynamicModal.scss";
import { Modal } from "antd";

const DynamicModal = (props) => {
  return (
    <Modal
      open={props.open}
      title={props.title}
      closable={props.closable ? props.closable : false}
      onCancel={props.close}
      footer={props.footer} 
      className={props.className}  
    >
      {props.children}
    </Modal>
  );
};

export default DynamicModal;
