// import React from "react";
// import "./App.scss";
// import { CustomLayout as Layout } from "./CommonComponents/layout/Layout";

// function App() {
//   return <Layout />;
// }

// export default App;



import React, { useContext } from "react";
import './App.scss';
import { CustomLayout as Layout } from './CommonComponents/layout/Layout';
import LoginPage from "./Pages/LoginPage/LoginPage";
import { AuthContext } from "./Context/AuthContext";

function App() {
  const { authToken } = useContext(AuthContext); // Get authToken from AuthContext

  // Render Layout if authenticated, otherwise render Login
  return authToken ? <Layout /> : <LoginPage />;
}

export default App;
