import React, { useState, useEffect } from "react";
import "./QuantityInput.scss";

const QuantityInput = ({ item, selectedQuantity, onQuantityChange, isValid }) => {
  const [quantity, setQuantity] = useState(selectedQuantity || "");
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    setQuantity(selectedQuantity || "");
  }, [selectedQuantity]);

  useEffect(() => {
    if (isValid) {
      setHasInteracted(false);
    }
  }, [isValid]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setQuantity(value);
      onQuantityChange(item.id, value ? parseInt(value) : null);
      setHasInteracted(true);
    }
  };

  const clearInput = () => {
    setQuantity("");
    onQuantityChange(item.id, null);
    setHasInteracted(true);
  };

  return (
    <div className="quantity-input-container align-self-end flex align-items-center gap-1">
      <div className="quantity-input-content">
        <input
          type="text"
          value={quantity}
          onChange={handleInputChange}
          placeholder="Qty."
          className={`quantity-input ${!isValid && hasInteracted ? "invalid" : ""}`}
        />
        {quantity && (
          <span className="clear-icon" onClick={clearInput}>
            &times;
          </span>
        )}
      </div>
    </div>
  );
};

export default QuantityInput;

